import React, { useState, useRef, useEffect } from 'react';
import SearchModalForChat, { SEARCH_TYPES } from './search-modal-for-chat';
import styles from './chat.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setNlMessage } from 'store/nl/actions';
import { MdClose } from 'react-icons/md';
import { selectConnection } from 'store/core/selectors';
import { formatVertexSearchResponse } from 'utils/formatters/search-formatter';

const Chat = ({ onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedContexts, setSelectedContexts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [, setCursorPosition] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const connection = useSelector(selectConnection);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPosition, setSearchPosition] = useState({ top: 0, left: 0 });
  const searchResultsRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastAtPosition, setLastAtPosition] = useState(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key.toLowerCase() === 'g') {
        e.preventDefault();
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onClose]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const determineLabel = (item) => {
    if (!item?.id) {
      return 'region';
    }
    const labelMap = {
      pers: 'person',
      educat: 'education',
      fund: 'fund',
      org: 'org',
      eng: 'eng',
    };
    return Object.entries(labelMap).find(([key]) => item.id.includes(key))?.[1];
  };

  const getCaretOffset = (root) => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return 0;
    const range = selection.getRangeAt(0);
    let offset = 0;

    const treeWalker = document.createTreeWalker(
      root,
      NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
      {
        acceptNode: (node) => {
          if (node.id === 'caret-marker') {
            return NodeFilter.FILTER_SKIP;
          }
          return NodeFilter.FILTER_ACCEPT;
        }
      },
      false
    );

    let currentNode = treeWalker.nextNode();
    while (currentNode) {
      if (currentNode === range.endContainer) {
        offset += range.endOffset;
        break;
      } else if (currentNode.nodeType === Node.TEXT_NODE) {
        offset += currentNode.textContent.length;
      }
      currentNode = treeWalker.nextNode();
    }
    return offset;
  };

  const addContext = (item) => {
    const text = inputValue;
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    let currentCursorOffset = 0;
    const nodes = Array.from(inputRef.current.childNodes);
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node === range.endContainer) {
        currentCursorOffset += range.endOffset;
        break;
      } else if (node.nodeType === Node.TEXT_NODE) {
        currentCursorOffset += node.textContent.length;
      } else if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('contextText')) {
        currentCursorOffset += node.outerHTML.length;
      }
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;
    let lastAtIndex = -1;
    let accumulatedLength = 0;

    const nodeFilter = {
      acceptNode: function (node) {
        if (node.nodeType === Node.TEXT_NODE &&
            (!node.parentNode || !node.parentNode.classList?.contains('contextText'))) {
          return NodeFilter.FILTER_ACCEPT;
        }
        if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('contextText')) {
          return NodeFilter.FILTER_ACCEPT;
        }
        return NodeFilter.FILTER_SKIP;
      }
    };

    const treeWalker = document.createTreeWalker(
      tempDiv,
      NodeFilter.SHOW_ALL,
      nodeFilter,
      false
    );

    let currentNode = treeWalker.nextNode();
    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        const atIndex = currentNode.textContent.lastIndexOf('@');
        if (atIndex !== -1) {
          lastAtIndex = accumulatedLength + atIndex;
        }
        accumulatedLength += currentNode.textContent.length;
      } else {
        accumulatedLength += currentNode.outerHTML.length;
      }
      currentNode = treeWalker.nextNode();
    }

    const label = determineLabel(item);
    const contextValue = {
      ...item,
      type: 'vertex',
      label: label,
      value: item.displayValue || item.metroRegion,
    };

    setSelectedContexts((prev) => [...prev, contextValue]);

    const markerId = "caret-marker";
    const markerHTML = `<span id="${markerId}"></span>`;

    const beforeAt = text.slice(0, lastAtIndex);
    const afterCursor = text.slice(currentCursorOffset);
    const newText =
      beforeAt +
      `\u200B<span contentEditable="false" class="contextText">@${contextValue.value}</span>\u200B` +
      markerHTML +
      afterCursor;

    setInputValue(newText);
    if (inputRef.current) {
      inputRef.current.innerHTML = newText;
      inputRef.current.focus();

      setTimeout(() => {
        const marker = document.getElementById(markerId);
        const selection = window.getSelection();
        if (marker) {
          const range = document.createRange();
          range.setStartAfter(marker);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
          const newCursorPos = getCaretOffset(inputRef.current);
          setCursorPosition(newCursorPos);
          marker.remove();
        } else {
          const newCursorPos = getCaretOffset(inputRef.current);
          setCursorPosition(newCursorPos);
        }
      }, 0);
    }

    setShowModal(false);
    setSearchType(null);
    setSearchResults([]);
    setLastAtPosition(null);
    setSelectedIndex(0);
  };

  const getCaretCoordinates = (element, position) => {
    const div = document.createElement('div');
    const style = div.style;
    const computed = window.getComputedStyle(element);

    style.whiteSpace = 'pre-wrap';
    style.wordWrap = 'break-word';
    style.position = 'absolute';
    style.visibility = 'hidden';
    style.width = computed.width;
    style.fontSize = computed.fontSize;
    style.fontFamily = computed.fontFamily;
    style.padding = computed.padding;
    style.border = computed.border;
    style.lineHeight = computed.lineHeight;

    div.textContent = element.innerText.substring(0, position);

    const span = document.createElement('span');
    span.textContent = element.innerText.substring(position) || '.';
    div.appendChild(span);

    document.body.appendChild(div);
    const { offsetLeft: spanX, offsetTop: spanY } = span;
    document.body.removeChild(div);

    const rect = element.getBoundingClientRect();

    return {
      top: rect.top + spanY,
      left: rect.left + spanX,
    };
  };

  const updateSearchPosition = (coords, resultCount) => {
    const itemHeight = 36;
    const padding = 6;
    const spacing = 20;
    const maxVisibleResults = 5;

    let numItems;
    if (!searchType) {
      numItems = SEARCH_TYPES.length;
    } else if (isLoading) {
      numItems = 1;
    } else if (searchResults.length === 0) {
      numItems = 1;
    } else {
      numItems = Math.min(searchResults.length, maxVisibleResults);
    }

    const contentHeight = numItems * itemHeight + padding;

    setSearchPosition({
      top: coords.top - contentHeight - spacing,
      left: coords.left,
    });
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [searchResults]);

  useEffect(() => {
    if (showModal && inputRef.current && lastAtPosition !== null) {
      const coords = getCaretCoordinates(inputRef.current, lastAtPosition);
      updateSearchPosition(coords, searchResults.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, isLoading, searchResults.length, lastAtPosition]);

  const handleInputChange = (e) => {
    const selection = window.getSelection();
    const range = selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;

    let value = e.target.innerHTML;
    const cursorPosition = getCaretPosition(e.target);
    
    let newAtIndex = -1;
    const currentNode = selection.anchorNode;

    const textBeforeCursor = Array.from(e.target.childNodes).reduce((acc, node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return acc + node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('contextText')) {
        return acc + node.textContent.replace('@', '-');
      }
      return acc;
    }, '');

    if (currentNode && currentNode.parentElement && currentNode.parentElement.classList.contains('contextText')) {
      setShowModal(false);
      setLastAtPosition(null);
    } else {
      newAtIndex = textBeforeCursor.lastIndexOf('@');
      if (newAtIndex !== -1) {
        setLastAtPosition(newAtIndex);
        const coords = getCaretCoordinates(e.target, newAtIndex);
        updateSearchPosition(coords, searchResults.length);
        setShowModal(true);
        if (!searchType) {
          setSearchResults([]);
        }
      } else {
        setShowModal(false);
        setLastAtPosition(null);
        setSearchType(null);
        setSearchResults([]);
      }
    }

    value = value.replace(
      /<span class="contextText"([^>]*)>([^<]*)<\/span>/g,
      (match, attrs, content) =>
        `\u200B<span contentEditable="false" class="contextText">${content}</span>\u200B`
    );

    if (inputRef.current) {
      const tokenNodes = inputRef.current.querySelectorAll('.contextText');
      const tokenValues = Array.from(tokenNodes).map((node) => node.textContent.trim());
      const newSelectedContexts = selectedContexts.filter((context) =>
        tokenValues.includes(`@${context.value}`)
      );
      if (newSelectedContexts.length !== selectedContexts.length) {
        setSelectedContexts(newSelectedContexts);
      }
    }

    if (inputRef.current) {
      const normalizedCurrent = inputRef.current.innerHTML.replace(/\u200B/g, '').trim();
      const normalizedValue = value.replace(/\u200B/g, '').trim();

      if (normalizedCurrent !== normalizedValue) {
        inputRef.current.innerHTML = value;
        if (range) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }

    setInputValue(value);
    setCursorPosition(cursorPosition);

    if (searchType && connection && showModal && lastAtPosition !== null) {
      const type = searchType.id === 'Nodes' ? 'vertex' : 'region';

      if (window.searchTimeout) {
        clearTimeout(window.searchTimeout);
      }

      window.searchTimeout = setTimeout(() => {
        const textAfterAt = textBeforeCursor.slice(lastAtPosition + 1, cursorPosition);
        if (textAfterAt) {
          setIsLoading(true);
          connection
            .invoke('filterSearch', textAfterAt, type)
            .then((response) => {
              if (response) {
                const formattedResults = formatVertexSearchResponse(response);
                setSearchResults(formattedResults);
                const newCoords = getCaretCoordinates(e.target, lastAtPosition);
                updateSearchPosition(newCoords, formattedResults.length);
              }
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } else {
          setSearchResults([]);
          setIsLoading(false);
        }
      }, 500);
    }
  };

  const handleTypeSelect = (type) => {
    setSearchType(type);
    setSearchResults([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = inputValue;

      selectedContexts.forEach((context) => {
        const contextSpans = tempDiv.querySelectorAll('.contextText');
        contextSpans.forEach((span) => {
          if (span.textContent === `@${context.value}`) {
            span.replaceWith(context.id || context.metroRegion);
          }
        });
      });

      let processedMessage = tempDiv.textContent
        .replace(/@/g, '')
        .replace(/\u200B/g, '')
        .trim();

      dispatch(setNlMessage(processedMessage, selectedContexts));
      setInputValue('');
      setShowModal(false);
      setSearchType(null);
      setSearchResults([]);
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (showModal) {
      const totalItems = searchType ? searchResults.length : SEARCH_TYPES.length;

      if (totalItems > 0) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          const newIndex = (selectedIndex + 1) % totalItems;
          setSelectedIndex(newIndex);

          const searchResultsContainer = searchResultsRef.current;
          const selectedItem = searchResultsContainer?.querySelector(`[data-index="${newIndex}"]`);
          if (selectedItem) {
            selectedItem.scrollIntoView({ block: 'nearest' });
          }
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          const newIndex = selectedIndex === 0 ? totalItems - 1 : selectedIndex - 1;
          setSelectedIndex(newIndex);

          const searchResultsContainer = searchResultsRef.current;
          const selectedItem = searchResultsContainer?.querySelector(`[data-index="${newIndex}"]`);
          if (selectedItem) {
            selectedItem.scrollIntoView({ block: 'nearest' });
          }
        } else if (e.key === 'Enter') {
          e.preventDefault();
          if (searchType) {
            addContext(searchResults[selectedIndex]);
          } else {
            handleTypeSelect(SEARCH_TYPES[selectedIndex]);
          }
        }
      }
    } else if (e.key === 'Backspace') {
      const selection = window.getSelection();
      if (selection.rangeCount > 0 && selection.getRangeAt(0).collapsed) {
        const range = selection.getRangeAt(0);
        let container = range.startContainer;
        let offset = range.startOffset;

        if (container.nodeType === Node.TEXT_NODE) {
          if (offset === 0) {
            const prev = container.previousSibling;
            if (
              prev &&
              prev.nodeType === Node.ELEMENT_NODE &&
              prev.classList.contains('contextText')
            ) {
              e.preventDefault();
              const contextLabel = prev.innerText;
              const contextValueWithoutAt = contextLabel.substring(1);
              prev.remove();
              setSelectedContexts((prevCtxs) =>
                prevCtxs.filter((ctx) => ctx.value !== contextValueWithoutAt)
              );
              setInputValue(inputRef.current.innerHTML);
              const newRange = document.createRange();
              newRange.setStart(container, 0);
              newRange.collapse(true);
              selection.removeAllRanges();
              selection.addRange(newRange);
              return;
            }
          }
        } else if (container.nodeType === Node.ELEMENT_NODE) {
          if (offset > 0) {
            const child = container.childNodes[offset - 1];
            if (
              child &&
              child.nodeType === Node.ELEMENT_NODE &&
              child.classList.contains('contextText')
            ) {
              e.preventDefault();
              const contextLabel = child.innerText;
              const contextValueWithoutAt = contextLabel.substring(1);
              child.remove();
              setSelectedContexts((prevCtxs) =>
                prevCtxs.filter((ctx) => ctx.value !== contextValueWithoutAt)
              );
              setInputValue(inputRef.current.innerHTML);
              const newRange = document.createRange();
              newRange.setStart(container, offset - 1);
              newRange.collapse(true);
              selection.removeAllRanges();
              selection.addRange(newRange);
              return;
            }
          }
        }
      }
    }

    if (e.key === 'Escape') {
      if (showModal) {
        setShowModal(false);
        setSearchType(null);
        setSearchResults([]);
      } else {
        onClose();
      }
      e.preventDefault();
    }
    if (e.key === 'Enter' && !showModal && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <>
      {showModal && (
        <div
          ref={searchResultsRef}
          className={styles.searchResultsWrapper}
          style={{
            position: 'fixed',
            top: searchPosition.top,
            left: searchPosition.left,
            maxHeight: `${((!searchType 
              ? SEARCH_TYPES.length 
              : isLoading || searchResults.length === 0
                ? 1
                : Math.min(searchResults.length, 5)) * 36) + 16}px`,
            overflowY: 'auto',
          }}
        >
          <div className={styles.searchResults}>
            <SearchModalForChat
              onClose={() => {
                setShowModal(false);
                setSearchType(null);
                setSearchResults([]);
              }}
              onSelect={searchType ? addContext : handleTypeSelect}
              searchType={searchType}
              searchResults={searchResults}
              isLoading={isLoading}
              selectedIndex={selectedIndex}
            />
          </div>
        </div>
      )}
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modalHeader}>
            <h3>Natural Language Search</h3>
            <p>
              {"Hey there!\n\nI am your AI assistant.\nI've got you covered!\n\nJust type what you need, and let's dive into making things happen."}
            </p>
          </div>
          <MdClose className={styles.close_icon} onClick={onClose} />
          {selectedContexts.length > 0 && (
            <div className={styles.selectedContexts}>
              {selectedContexts.map((context, index) => (
                <div key={index} className={styles.contextItem}>
                  <span className={`${styles.contextLabel} ${styles[context.label]}`}>
                    {`${context.label}:`}
                  </span>
                  <span className={styles.contextValue}>{context.value}</span>
                </div>
              ))}
            </div>
          )}
          <div className={styles.chatContainer}>
            <form onSubmit={handleSubmit} className={styles.inputForm}>
              <div className={styles.inputWrapper}>
                <div
                  ref={inputRef}
                  contentEditable
                  className={styles.input}
                  onInput={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type @ to add context..."
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const getCaretPosition = (element) => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return 0;
  
  const range = selection.getRangeAt(0);
  let offset = 0;
  
  const treeWalker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false);
  let currentNode = treeWalker.nextNode();
  
  while (currentNode) {
    if (currentNode === range.endContainer) {
      offset += range.endOffset;
      break;
    } else {
      offset += currentNode.textContent.length;
    }
    currentNode = treeWalker.nextNode();
  }
  return offset;
};

export default Chat;
