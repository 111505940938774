import { put, select, call } from 'redux-saga/effects';
import { selectConnection } from 'store/core/selectors';
import { setGraphItem, setIsLoadingGraphItems } from 'store/graph/actions';
import { getItems } from 'utils/graph-helper';
import { readBlob } from 'utils/query-helper';

const nlQuery = async (connection, action) => {
  const { message, contexts } = action;
  console.log(message, contexts);
  const res = await connection.invoke('nl_query', message, contexts);
  console.log(res);
  return await readBlob(res, true);
};

export function* nlQuerySaga(action) {
  yield put(setIsLoadingGraphItems(true));
  const connection = yield select(selectConnection);

  try {
    const response = yield call(nlQuery, connection, action);
    console.log(response);
    
    // Handle each context separately
    for (const context of action.contexts) {
      const { nodes, links, nodeCount } = getItems(response, context.id);
      if (nodes != null) {
        yield put(setGraphItem(context, nodes, links, nodeCount));
      }
    }
    
    yield put(setIsLoadingGraphItems(false));
  } catch (error) {
    console.log('Error in nlQuerySaga:', error);
    yield put(setIsLoadingGraphItems(false));
  }
}
